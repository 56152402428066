




























































import {Vue, Component} from "vue-property-decorator";

@Component({
  name: "NewPlayerResponsive",
})

export default class NewPlayerResponsive extends Vue {

}
